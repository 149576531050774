<template>
    <div class="flex justify-center items-center h-screen">
      <p>正在退出登录，请稍候...</p>
    </div>
  </template>
  
  <script>
  import apiClient from '@/plugins/axios';
  
  export default {
    name: 'UserLogout',
    mounted() {
      this.logoutUser();
    },
    methods: {
      async logoutUser() {
        try {
          // 调用后端的登出 API
          await apiClient.post('/logout');
          
          // 清除 localStorage 中的 token
          localStorage.removeItem('auth_token');
          
          // 清除 cookies （如有需要）
          document.cookie = "laravel_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          
          // 跳转到登录页面
          this.$router.push('/login');
        } catch (error) {
          console.error('退出登录失败:', error);
          // 如果登出失败，留在当前页面或处理错误
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* 样式根据需要调整 */
  </style>
  