<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-gray-50 px-4 sm:px-6 lg:px-8">
    <!-- 注册标题 -->
    <h2 class="text-3xl font-bold text-gray-800 mb-4">注册</h2>

    <!-- 注册表单 -->
    <form @submit.prevent="register" class="w-full max-w-md bg-white shadow-lg rounded-lg p-6 sm:p-8">
      <!-- 用户名输入框 -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2" for="name">用户名:</label>
        <input
          v-model="form.name"
          type="text"
          id="name"
          placeholder="请输入用户名"
          class="px-4 py-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <!-- 邮箱输入框 -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2" for="email">邮箱:</label>
        <input
          v-model="form.email"
          type="email"
          id="email"
          placeholder="请输入邮箱"
          class="px-4 py-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <!-- 密码输入框 -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2" for="password">密码:</label>
        <input
          v-model="form.password"
          type="password"
          id="password"
          placeholder="请输入密码"
          class="px-4 py-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <!-- 确认密码输入框 -->
      <div class="mb-6">
        <label class="block text-gray-700 font-medium mb-2" for="password_confirmation">确认密码:</label>
        <input
          v-model="form.password_confirmation"
          type="password"
          id="password_confirmation"
          placeholder="请确认密码"
          class="px-4 py-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <!-- 注册按钮 -->
      <button
        type="submit"
        class="px-6 py-3 w-full bg-green-500 text-white rounded hover:bg-green-600 transition duration-150 ease-in-out"
      >
        注册
      </button>
    </form>

    <!-- 登录链接 -->
    <router-link to="/login" class="mt-4 text-blue-500 hover:underline">
      已有账号？点击登录
    </router-link>

    <!-- 返回主页按钮 -->
    <a href="https://stock.meogqy.com/" class="mt-6 px-6 py-3 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition duration-150 ease-in-out">
      返回网站首页
    </a>
  </div>
</template>

<script>
import apiClient from '@/plugins/axios';

export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
    };
  },
  methods: {
    async register() {
      try {
        const response = await apiClient.post('/register', this.form);
        if (response && response.data) {
          alert('注册成功');
          this.$router.push({ name: 'login' });
        }
      } catch (error) {
        if (error.response && error.response.status === 422) {
          alert('注册失败: 该邮箱已经被注册，请使用另一个邮箱。');
        } else {
          alert('注册失败: 发生了错误，请稍后再试。');
        }
      }
    },
  },
};
</script>

<style scoped>
/* 背景颜色 */
.bg-gray-50 {
  background-color: #f9fafb;
}

/* 标题颜色 */
.text-gray-800 {
  color: #2d3748;
}

/* 边框颜色 */
.border {
  border-color: #e2e8f0;
}

/* 焦点效果 */
.focus\:ring-2 {
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}

/* 按钮背景颜色 */
.bg-green-500 {
  background-color: #10b981;
}

.bg-green-600 {
  background-color: #059669;
}

.text-white {
  color: white;
}

/* 链接颜色 */
.text-blue-500 {
  color: #3b82f6;
}

/* 返回按钮颜色 */
.bg-gray-300 {
  background-color: #e2e8f0;
}

.bg-gray-400 {
  background-color: #cbd5e0;
}

.text-gray-700 {
  color: #4a5568;
}

/* 卡片阴影和圆角 */
.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.rounded-lg {
  border-radius: 0.5rem;
}
</style>
