<template>
  <AppLayout>
    <template #header>
      <h2 class="text-xl font-semibold text-gray-800 leading-tight">Super Big Bull Dashboard</h2>
    </template>

    <div class="flex flex-col h-full p-6">
      <!-- 数据统计信息 -->
      <div class="flex justify-between items-center mb-4">
        <div class="text-gray-700">
          <span class="font-semibold">符合条件的记录总数：</span>{{ totalCount }}
        </div>
        <div class="text-gray-700">
          <span class="font-semibold">最新交易日期：</span>{{ maxTradeDate }}
        </div>
      </div>

      <!-- 筛选栏 -->
      <div class="flex flex-col sm:flex-row gap-4 mb-6">
        <div class="w-full sm:w-1/3">
          <label class="block text-sm font-medium text-gray-700">市场</label>
          <select v-model="selectedMarket" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm">
            <option value="">所有市场</option>
            <option v-for="market in marketOptions" :key="market" :value="market">{{ market }}</option>
          </select>
        </div>
        <div class="w-full sm:w-1/3">
          <label class="block text-sm font-medium text-gray-700">实控人名称</label>
          <input
            type="text"
            v-model="selectedActName"
            class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            placeholder="输入实控人名称"
          />
        </div>
        <div class="w-full sm:w-1/3">
          <label class="block text-sm font-medium text-gray-700">是否融资</label>
          <select v-model="selectedMargin" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm">
            <option value="">全部</option>
            <option value="融">是</option>
            <option value="无">否</option>
          </select>
        </div>
      </div>

      <!-- 数据表格（桌面端） -->
      <div class="hidden lg:block">
        <table class="min-w-full divide-y divide-gray-200 shadow-lg rounded-lg overflow-hidden">
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase">股票名称</th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase">市场</th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase w-48">实控人名称</th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase">是否融资</th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase">收盘价</th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase">EMA5</th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase">SMA5</th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase">EMA10</th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase">SMA10</th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase">EMA20</th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase">SMA20</th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase">EMA60</th>
              <th scope="col" class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase">SMA60</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr
              v-for="stock in filteredStockData"
              :key="stock.ts_code"
              class="hover:bg-gray-50"
            >
              <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-700">
                <a :href="generateTradingViewLink(stock.ts_code)" target="_blank">{{ stock.name }}</a>
              </td>
              <td class="px-4 py-3 whitespace-nowrap">{{ stock.market }}</td>
              <td class="px-4 py-3 whitespace-nowrap w-48 text-ellipsis overflow-hidden" :title="stock.act_name">{{ stock.act_name }}</td>
              <td class="px-4 py-3 whitespace-nowrap">{{ stock.is_margin }}</td>
              <td class="px-4 py-3 whitespace-nowrap">{{ stock.close }}</td>
              <td class="px-4 py-3 whitespace-nowrap">{{ stock.EMA5 }}</td>
              <td class="px-4 py-3 whitespace-nowrap">{{ stock.SMA5 }}</td>
              <td class="px-4 py-3 whitespace-nowrap">{{ stock.EMA10 }}</td>
              <td class="px-4 py-3 whitespace-nowrap">{{ stock.SMA10 }}</td>
              <td class="px-4 py-3 whitespace-nowrap">{{ stock.EMA20 }}</td>
              <td class="px-4 py-3 whitespace-nowrap">{{ stock.SMA20 }}</td>
              <td class="px-4 py-3 whitespace-nowrap">{{ stock.EMA60 }}</td>
              <td class="px-4 py-3 whitespace-nowrap">{{ stock.SMA60 }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- 移动端数据展示 -->
      <div class="lg:hidden">
        <div v-for="stock in filteredStockData" :key="stock.ts_code" class="bg-white shadow rounded-lg mb-4 p-4">
          <h3 class="text-lg font-semibold text-gray-800 mb-2">
            <a :href="generateTradingViewLink(stock.ts_code)" target="_blank">{{ stock.name }}</a>
          </h3>
          <div class="text-gray-700 text-sm">
            <div><span class="font-semibold">市场：</span>{{ stock.market }}</div>
            <div><span class="font-semibold">实控人：</span>{{ stock.act_name }}</div>
            <div><span class="font-semibold">是否融资：</span>{{ stock.is_margin }}</div>
            <div><span class="font-semibold">收盘价：</span>{{ stock.close }}</div>
            <div><span class="font-semibold">EMA5：</span>{{ stock.EMA5 }}</div>
            <div><span class="font-semibold">SMA5：</span>{{ stock.SMA5 }}</div>
            <div><span class="font-semibold">EMA10：</span>{{ stock.EMA10 }}</div>
            <div><span class="font-semibold">SMA10：</span>{{ stock.SMA10 }}</div>
            <div><span class="font-semibold">EMA20：</span>{{ stock.EMA20 }}</div>
            <div><span class="font-semibold">SMA20：</span>{{ stock.SMA20 }}</div>
            <div><span class="font-semibold">EMA60：</span>{{ stock.EMA60 }}</div>
            <div><span class="font-semibold">SMA60：</span>{{ stock.SMA60 }}</div>
          </div>
        </div>
      </div>

      <div v-if="!calfLoading && filteredStockData.length === 0" class="text-center mt-4">没有可显示的数据。</div>
    </div>
  </AppLayout>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import apiClient from '@/plugins/axios';

export default {
  setup() {
    const calfStockData = ref([]);
    const calfLoading = ref(true);
    const totalCount = ref(0);
    const maxTradeDate = ref('');
    const selectedMarket = ref('');
    const selectedActName = ref('');
    const selectedMargin = ref('');

    const filteredStockData = computed(() => {
      return calfStockData.value.filter((stock) => {
        return (
          (selectedMarket.value === '' || stock.market === selectedMarket.value) &&
          (selectedActName.value === '' || stock.act_name?.includes(selectedActName.value)) &&
          (selectedMargin.value === '' || (selectedMargin.value === '融' ? stock.is_margin === '融' : stock.is_margin !== '融'))
        );
      });
    });

    const marketOptions = computed(() => [...new Set(calfStockData.value.map(stock => stock.market))]);

    const generateTradingViewLink = (ts_code) => {
      const prefix = ts_code.endsWith('.SZ') ? 'SZSE' : ts_code.endsWith('.SH') ? 'SSE' : 'Unknown';
      const symbol = ts_code.slice(0, -3);
      return `https://cn.tradingview.com/chart/g25qQEp8/?symbol=${prefix}%3A${symbol}`;
    };

    const fetchStockData = async () => {
      calfLoading.value = true;
      try {
        const response = await apiClient.get('/get-super-big-bull', { withCredentials: true });
        calfStockData.value = response.data.data;
        totalCount.value = response.data.total_count;
        maxTradeDate.value = response.data.data.length ? response.data.data[0].trade_date : '';
      } catch (error) {
        console.error('无法获取股票数据，请稍后再试。');
      } finally {
        calfLoading.value = false;
      }
    };

    onMounted(() => {
      fetchStockData();
    });

    return {
      calfStockData,
      calfLoading,
      totalCount,
      maxTradeDate,
      selectedMarket,
      selectedActName,
      selectedMargin,
      filteredStockData,
      marketOptions,
      generateTradingViewLink,
    };
  },
};
</script>
