//src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import UserRegister from '@/pages/Auth/UserRegister.vue';
import UserLogin from '@/pages/Auth/UserLogin.vue';
import ChangePassword from '@/pages/Auth/ChangePassword.vue';
import HomePage from '@/pages/HomePage.vue';
import IndexPage from '@/pages/index.vue';
import UserPosition from '@/pages/UserPosition.vue';
import TradeRecordCreate from '@/pages/TradeRecords/CreateTradeRecord.vue';
import TradeRecordIndex from '@/pages/TradeRecords/IndexTradeRecord.vue';
import TradeRecordShow from '@/pages/TradeRecords/ShowTradeRecord.vue';
import UserLogout from '@/pages/Auth/UserLogout.vue';
import TradePrinciplesPage from '@/pages/TradePrinciplesPage.vue';
import MovingAverageCrossing from '@/pages/MovingAverageCrossing.vue';
import StockPriceBreakthrough from '@/pages/StockPriceBreakthrough.vue';
import HeatSlope from '@/pages/HeatSlope.vue';
import TechnicalIndicators from '@/pages/TechnicalIndicators.vue';

// 定义路由
const routes = [
  { path: '/', name: 'index', component: IndexPage, meta: { title: '欢迎来到 My Trading Strategy' },},  // 网站首页
  { path: '/register', name: 'register', component: UserRegister, meta: { title: '注册 - My Trading Strategy' },  },   // 用户注册
  { path: '/login', name: 'login', component: UserLogin, meta: { title: '登录 - My Trading Strategy' }, },   // 用户登录
  { path: '/change-password', name: 'change-password', component: ChangePassword, meta: { requiresAuth: true, title: '修改密码 - My Trading Strategy'} }, // 需要登录
  { path: '/home', name: 'home', component: HomePage, meta: { requiresAuth: true, title: '首页 - My Trading Strategy'  } },  // 需要登录
  { path: '/user-positions', name: 'user-positions', component: UserPosition, meta: { requiresAuth: true, title: '仓位管理 - My Trading Strategy'} },  // 需要登录
  { path: '/trading-principles', name: 'trading-principles', component: TradePrinciplesPage, meta: { requiresAuth: true, title: '交易原则 - My Trading Strategy'} },  // 需要登录
  { path: '/trade-records/create', name: 'trade-record-create', component: TradeRecordCreate, meta: { requiresAuth: true, title: '创建交易记录 - My Trading Strategy'  } },  // 需要登录
  { path: '/trade-records', name: 'trade-record-index', component: TradeRecordIndex, meta: { requiresAuth: true, title: '交易记录列表 - My Trading Strategy'  } },  // 需要登录
  { path: '/moving-average-crossing', name: 'moving-average-crossing', component: MovingAverageCrossing, meta: { requiresAuth: true, title: '均线穿越 - My Trading Strategy' } },
  { path: '/stock-price-breakthrough', name: 'stock-price-breakthrough', component: StockPriceBreakthrough, meta: { requiresAuth: true, title: '股价突破 - My Trading Strategy' } },
  { path: '/heat-slope', name: 'heat-slope', component: HeatSlope, meta: { requiresAuth: true, title: '热度斜率 - My Trading Strategy' } },
  { path: '/technical-indicators', name: 'technical-indicators', component: TechnicalIndicators, meta: { requiresAuth: true, title: '技术指标 - My Trading Strategy' } },
  { path: '/trade-records/:id', name: 'trade-record-show', component: TradeRecordShow, meta: { requiresAuth: true, title: '交易详情 - My Trading Strategy'  } },  // 需要登录
  { path: '/logout', name: 'logout', component: UserLogout },
];

// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 全局导航守卫
router.beforeEach((to, from, next) => {
  // 检查路由是否需要登录
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 检查用户是否已登录
    const isAuthenticated = !!localStorage.getItem('auth_token');  // 假设使用 localStorage 存储 token

    if (!isAuthenticated) {
      // 如果未登录，跳转到登录页面
      next({ name: 'login' });
    } else {
      // 已登录，继续导航
      next();
    }
  } else {
    // 不需要登录，直接继续导航
    next();
  }
});

export default router;
