<template>
  <nav class="bg-white border-b border-gray-100">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center py-4">
        <!-- 左侧菜单 -->
        <div class="flex items-center">
          <div class="shrink-0">
            <a href="/">
              <img class="h-10 w-auto" src="/logo.png" alt="Logo">
            </a>
          </div>
          <!-- Desktop Menu -->
          <div class="hidden sm:flex sm:ml-10 space-x-8">
            <router-link to="/home" class="nav-link">首页</router-link>
            <router-link to="/moving-average-crossing" class="nav-link">均线穿越</router-link>
            <router-link to="/stock-price-breakthrough" class="nav-link">股价突破</router-link>
            <router-link to="/heat-slope" class="nav-link">热度斜率</router-link>
            <router-link to="/technical-indicators" class="nav-link">技术指标</router-link>
            <router-link to="/trade-records" class="nav-link">我的交易</router-link>
            <router-link to="/user-positions" class="nav-link">仓位管理</router-link>
            <router-link to="/trading-principles" class="nav-link">交易原则</router-link>
          </div>
        </div>

        <!-- Mobile Menu Button -->
        <div class="sm:hidden">
          <button @click="open = !open" :aria-expanded="open.toString()" class="text-gray-500 hover:text-gray-700 focus:outline-none">
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
            </svg>
          </button>
        </div>

        <!-- 右侧用户菜单 -->
        <div class="hidden sm:flex sm:items-center">
          <div class="relative ml-3">
            <button @click="open = !open" class="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out">
              <img class="h-8 w-8 rounded-full" src="/user-avatar.png" alt="User Avatar">
            </button>
            <transition name="fade">
              <div v-if="open" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
                <router-link to="/profile" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">修改密码</router-link>
                <router-link to="/logout" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">退出登录</router-link>
              </div>
            </transition>
          </div>
        </div>
      </div>

      <!-- Mobile Menu -->
      <div v-if="open" class="sm:hidden">
        <div class="pt-2 pb-3 space-y-1">
          <router-link to="/home" class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300">首页</router-link>
          <router-link to="/moving-average-crossing" class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300">均线穿越</router-link>
          <router-link to="/stock-price-breakthrough" class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300">股价突破</router-link>
          <router-link to="/heat-slope" class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300">热度斜率</router-link>
          <router-link to="/technical-indicators" class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300">技术指标</router-link>
          <router-link to="/trade-records" class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300">我的交易</router-link>
          <router-link to="/user-positions" class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300">仓位管理</router-link>
          <router-link to="/trading-principles" class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300">交易原则</router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavigationBar',
  data() {
    return {
      open: false,
    };
  }
};
</script>

<style scoped>
.nav-link {
  @apply text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out;
}
</style>
