<!-- src/pages/Auth/ChangePassword.vue -->
<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-gray-50">
    <h2 class="text-3xl font-bold text-gray-800">修改密码</h2>
    <form class="mt-6 w-full max-w-sm">
      <input type="password" placeholder="当前密码" class="mb-4 px-4 py-2 w-full border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"/>
      <input type="password" placeholder="新密码" class="mb-4 px-4 py-2 w-full border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"/>
      <input type="password" placeholder="确认新密码" class="mb-6 px-4 py-2 w-full border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"/>
      <button type="submit" class="px-6 py-3 w-full bg-green-500 text-white rounded hover:bg-green-600">修改密码</button>
    </form>
  </div>
</template>


<script>
import apiClient from '@/plugins/axios'; // 引入配置好的 Axios 实例

export default {
  data() {
    return {
      form: {
        current_password: '',
        password: '',
        password_confirmation: '',
      },
    };
  },
  methods: {
    async changePassword() {
      try {
        const response = await apiClient.post('/password/change', this.form);
        console.log('密码修改成功:', response.data);
        // 处理成功后的逻辑
      } catch (error) {
        console.error('密码修改失败:', error.response.data);
        // 处理错误
      }
    },
  },
};
</script>
