// 引入 Tailwind CSS 样式
import './assets/tailwind.css';

// 引入 Vue 和相关模块
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from './plugins/axios'; // 引入 axios 实例

// 创建 Vue 应用
const app = createApp(App);

// 将 axios 实例注入到 Vue 的全局属性中
app.config.globalProperties.$axios = axios;

// 使用路由并挂载应用
app.use(router).mount('#app');

// 动态设置页面标题
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
