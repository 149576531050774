<template>
  <AppLayout title="我的交易记录">
    <template #header>
      <h2 class="font-semibold text-xl text-gray-800 leading-tight">我的交易记录</h2>
    </template>

    <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div class="bg-white shadow-xl sm:rounded-lg p-6">

          <!-- 顶部部分，包括标题和按钮 -->
          <div class="flex justify-between items-center mb-6">
            <h3 class="text-lg font-medium text-gray-700">交易记录列表</h3>
            <router-link to="/trade-records/create" class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 shadow-md">
              创建交易记录
            </router-link>
          </div>

          <!-- 交易记录列表 -->
          <div v-if="sortedTradeRecords.length > 0 && !loading" class="space-y-6">
            <div v-for="record in sortedTradeRecords" :key="record.id" class="border border-gray-200 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow ease-in-out duration-300">
           
            <!-- 第1层：股票信息和操作按钮（在一行） -->
            <div class="flex justify-between items-center mb-4">
              <h4 class="text-lg font-semibold text-gray-800">
                {{ record.stock?.name || '未知股票' }} ({{ record.stock?.symbol || '未知代码' }})
              </h4>
              <div class="flex flex-wrap justify-end space-x-3">
                <button @click="viewDetails(record.id)" class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow mb-2 sm:mb-0">
                  查看详情
                </button>
                <button @click="deleteRecord(record.id)" class="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg shadow">
                  删除
                </button>
              </div>
            </div>

              <!-- 第2层：股票价格信息（单独一行） -->
              <div class="flex flex-wrap gap-x-4 items-center mb-2 text-sm">
                <span class="font-bold text-lg">
                  收盘价: {{ record.close || '未知' }} 
                  ({{ record.pct_chg >= 0 ? '+' : '' }}{{ record.pct_chg || '未知' }})
                </span>
                <span class="text-gray-500 font-normal">开盘价: {{ record.open || '未知' }}</span>
                <span class="text-gray-500 font-normal">最高价: {{ record.high || '未知' }}</span>
                <span class="text-gray-500 font-normal">最低价: {{ record.low || '未知' }}</span>
                <span class="text-gray-500 font-normal">成交量: {{ record.vol || '未知' }}</span>
                <span class="text-gray-500 font-normal">交易日期: {{ record.trade_date ? record.trade_date : '未知' }}</span>
              </div>

              <!-- 第3层：基础信息和盈亏信息 -->
              <div class="flex flex-wrap justify-between">
                <div class="flex-1 space-y-4">
                  <!-- 3.1层：基础信息展示 -->
                  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                    <div class="flex flex-col">
                      <span class="text-gray-600">开仓日期</span>
                      <span class="font-semibold text-gray-800">{{ record.opening_date || '未知日期' }}</span>
                    </div>
                    <div class="flex flex-col">
                      <span class="text-gray-600">开仓价格</span>
                      <span class="font-semibold text-gray-800">{{ record.initial_opening_price || '未知价格' }}</span>
                    </div>
                    <div class="flex flex-col">
                      <span class="text-gray-600">止损价格</span>
                      <span class="font-semibold text-gray-800">{{ record.initial_stop_loss_price || '未知价格' }}</span>
                    </div>
                    <div class="flex flex-col">
                      <span class="text-gray-600">止盈价格</span>
                      <span class="font-semibold text-gray-800">{{ record.initial_target_profit_price || '未知价格' }}</span>
                    </div>
                  </div>

                  <!-- 3.2层：盈亏比与持仓信息展示 -->
                  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                    <div class="flex flex-col">
                      <span class="text-gray-600">盈亏比</span>
                      <span class="font-semibold text-gray-800">{{ record.risk_reward_ratio || '尚未计算' }}</span>
                    </div>
                    <div class="flex flex-col">
                      <span class="text-gray-600">持仓成本</span>
                      <span class="font-semibold text-gray-800">{{ record.holdings?.average_cost || '未知价格' }}</span>
                    </div>
                    <div class="flex flex-col">
                      <span class="text-gray-600">持股数量</span>
                      <span class="font-semibold text-gray-800">{{ record.holdings?.remaining_shares || '未知数量' }}</span>
                    </div>
                    <div class="flex flex-col">
                      <span class="text-gray-600">持仓价值</span>
                      <span class="font-semibold text-gray-800">
                        {{ record.holdings?.remaining_shares && record.close ? (record.holdings.remaining_shares * record.holdings.average_cost).toFixed(2) : '未知金额' }}
                      </span>
                    </div>
                  </div>
                </div>

                <!-- 3.3层：盈亏比 -->
                <div class="flex items-center justify-center bg-green-100 p-4 ml-4 w-32 h-full rounded-lg shadow-md">
                  <span class="text-xl font-semibold text-green-700">
                    {{ record.risk_reward_ratio || '未计算' }}
                  </span>
                </div>
              </div>

            </div>
          </div>

          <!-- 如果没有交易记录 -->
          <div v-else-if="!loading" class="mt-4">
            <p class="text-gray-500 mb-4">尚未创建任何交易记录。</p>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import apiClient from '@/plugins/axios';

export default {
  setup() {
    const router = useRouter();

    const tradeRecords = ref([]);  // 用于存储交易记录
    const stockPrices = ref({});   // 用于存储股票价格（动态）
    const loading = ref(true);     // 控制加载状态

    // 第一次加载时获取交易记录
    const fetchTradeRecords = async () => {
      try {
        const tradeResponse = await apiClient.get('/trade-records');
        tradeRecords.value = tradeResponse.data.tradeRecords || [];
      } catch (error) {
        console.error('获取交易记录出错:', error);
      } finally {
        loading.value = false;
      }
    };

    // 获取股票价格
    const fetchStockPrices = async () => {
      if (tradeRecords.value.length > 0) {
        const stockSymbols = tradeRecords.value.map(record => record.symbol);

        try {
          const priceResponse = await apiClient.get('/stock-prices-by-symbols', {
            params: {
              symbols: stockSymbols
            }
          });

          // 将价格数据存储为以 symbol 为键的对象
          stockPrices.value = priceResponse.data.stocks.reduce((acc, stock) => {
            acc[stock.symbol] = stock;
            return acc;
          }, {});
        } catch (error) {
          console.error('获取股票价格出错:', error);
        }
      }
    };

    // 页面加载时调用
    onMounted(() => {
      fetchTradeRecords().then(() => {
        fetchStockPrices();   // 确保 tradeRecords 加载后再加载股票价格
      });
    });

    // 合并交易记录和股票价格
    const sortedTradeRecords = computed(() => {
      return tradeRecords.value.map(record => {
        const stockPrice = stockPrices.value[record.symbol] || {};
        return {
          ...record,
          ...stockPrice  // 合并动态价格数据
        };
      }).sort((a, b) => (a.is_closed ? 1 : 0) - (b.is_closed ? 1 : 0));
    });

    const viewDetails = (id) => {
      router.push({ name: 'trade-record-show', params: { id } });
    };

  const deleteRecord = async (id) => {
    try {
      // 发送删除请求到后端
      await apiClient.delete(`/trade-records/${id}`);
      
      // 删除成功后，从本地的 tradeRecords 中移除该记录
      tradeRecords.value = tradeRecords.value.filter(record => record.id !== id);
      
      // 可以提示用户删除成功
      alert(`交易记录 ID: ${id} 已成功删除`);
    } catch (error) {
      console.error('删除交易记录失败:', error);
      alert('删除交易记录时出错，请稍后再试');
    }
  };


    return {
      tradeRecords,
      stockPrices,
      loading,
      sortedTradeRecords,
      viewDetails,
      deleteRecord
    };
  }
};
</script>
<style scoped>
@media (max-width: 640px) {
  .text-lg {
    font-size: 1rem;
  }

  .font-semibold {
    font-weight: 600;
  }

  .bg-blue-500, .bg-red-500 {
    padding: 10px 16px;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .mb-6 {
    margin-bottom: 16px;
  }

  .p-6 {
    padding: 12px;
  }

  .text-gray-700 {
    color: #4a4a4a;
  }

  .bg-green-100 {
    background-color: #d1fae5;
  }

  .text-gray-800 {
    color: #2d3748;
  }

  .text-gray-500 {
    color: #6b7280;
  }

  .text-xl {
    font-size: 1.25rem;
  }
}
</style>
