import axios from 'axios';

// 创建 Axios 实例
const apiClient = axios.create({
  baseURL: 'https://api.meogqy.com/api/', // 后端 API 的基础 URL
  headers: {
    'Content-Type': 'application/json', // 设置默认的 Content-Type 头
  },
});

// 请求拦截器
apiClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('auth_token'); // 从 localStorage 获取存储的 token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // 在请求头中添加 Authorization
    }
    return config;
  },
  error => {
    return Promise.reject(error); // 处理请求错误
  }
);

// 响应拦截器（可选）
apiClient.interceptors.response.use(
  response => {
    return response; // 对响应数据做一些处理（如果需要）
  },
  error => {
    if (error.response && error.response.status === 401) {
      // 处理未授权的情况，例如重定向到登录页
      console.error('Unauthorized, redirecting to login...');
      // 可以执行重定向操作，如 window.location.href = '/login';
    }
    return Promise.reject(error); // 处理响应错误
  }
);

export default apiClient;
