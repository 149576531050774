<template>
  <AppLayout>
    <template #header>
      <h2 class="text-xl font-semibold text-gray-800 leading-tight">Buy it for Now</h2>
    </template>

    <div class="flex flex-col h-full p-6 space-y-4">
      <!-- Tab 切换（适配移动端） -->
      <div class="flex flex-wrap justify-start space-x-2 mb-4">
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          class="px-4 py-2 text-sm font-medium sm:text-base rounded-md border transition-colors duration-150"
          :class="{'bg-blue-500 text-white': activeTab === tab, 'text-gray-700': activeTab !== tab}"
          @click="setActiveTab(tab)"
        >
          {{ tab }}
        </button>
      </div>

      <!-- 股票数量和更新日期 -->
      <div v-if="activeTab !== '个股搜索'" class="flex justify-between items-center">
        <span class="text-sm text-gray-600">更新日期：<strong>{{ stockDates[activeTab] || '暂无数据' }}</strong></span>
        <span class="text-sm text-gray-600">股票数量：<strong>{{ stockCounts[activeTab] || 0 }}</strong></span>
      </div>

      <!-- 个股搜索框 -->
      <div v-if="activeTab === '个股搜索'" class="flex mb-4">
        <input
          v-model="searchQuery"
          @keyup.enter="searchStock"
          type="text"
          class="w-full p-2 border border-gray-300 rounded-md"
          placeholder="输入股票代码或名称查询"
        />
        <button @click="searchStock" class="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md">查询</button>
      </div>

      <!-- 错误信息 -->
      <div v-if="errorMessage" class="text-red-500 text-center mb-4">{{ errorMessage }}</div>

      <!-- 加载中提示 -->
      <div v-if="loading" class="text-center text-gray-600">正在加载中...</div>

      <!-- 表格数据展示 -->
      <div v-if="!loading && stockData.length > 0">
        <div class="overflow-x-auto">
          <table class="min-w-full divide-y divide-gray-200 table-auto">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">股票名称</th>
                <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase">收盘价</th>
                <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase">MA5</th>
                <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase">MA10</th>
                <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase">MA20</th>
                <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase">MA30</th>
                <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase">MA60</th>
                <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase">MA120</th>
                <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase">EMA5</th>
                <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase">EMA10</th>
                <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase">EMA20</th>
                <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase">EMA30</th>
                <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase">EMA60</th>
                <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase">EMA120</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="stock in stockData" :key="stock.ts_code">
                <td class="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">
                  <a :href="generateTradingViewLink(stock.ts_code)" target="_blank" class="text-blue-500 hover:underline">{{ stock.name }}</a>
                </td>
                <td class="px-4 py-2 text-center">{{ stock.close }}</td>
                <td class="px-4 py-2 text-center">{{ stock.MA5 }}</td>
                <td class="px-4 py-2 text-center">{{ stock.MA10 }}</td>
                <td class="px-4 py-2 text-center">{{ stock.MA20 }}</td>
                <td class="px-4 py-2 text-center">{{ stock.MA30 }}</td>
                <td class="px-4 py-2 text-center">{{ stock.MA60 }}</td>
                <td class="px-4 py-2 text-center">{{ stock.MA120 }}</td>
                <td class="px-4 py-2 text-center">{{ stock.EMA5 }}</td>
                <td class="px-4 py-2 text-center">{{ stock.EMA10 }}</td>
                <td class="px-4 py-2 text-center">{{ stock.EMA20 }}</td>
                <td class="px-4 py-2 text-center">{{ stock.EMA30 }}</td>
                <td class="px-4 py-2 text-center">{{ stock.EMA60 }}</td>
                <td class="px-4 py-2 text-center">{{ stock.EMA120 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 无数据提示 -->
      <div v-if="!loading && stockData.length === 0" class="text-center mt-4 text-gray-600">没有可显示的数据。</div>
    </div>
  </AppLayout>
</template>

<script>
import { ref, onMounted } from 'vue';
import apiClient from '@/plugins/axios';

export default {
  setup() {
    const stockData = ref([]);
    const loading = ref(false);
    const errorMessage = ref('');
    const stockDates = ref({
      '嫩牛': '',
      '小牛': '',
      '大牛': '',
    });
    const stockCounts = ref({
      '嫩牛': 0,
      '小牛': 0,
      '大牛': 0,
    });
    const activeTab = ref('嫩牛');
    const tabs = ref(['嫩牛', '小牛', '大牛', '个股搜索']);
    const searchQuery = ref('');

    // 生成 TradingView 链接
    const generateTradingViewLink = (ts_code) => {
      const prefix = ts_code.endsWith('.SZ') ? 'SZSE' : ts_code.endsWith('.SH') ? 'SSE' : 'Unknown';
      const symbol = ts_code.slice(0, -3);
      return `https://cn.tradingview.com/chart/g25qQEp8/?symbol=${prefix}%3A${symbol}`;
    };

    // 获取不同状态的股票数据
    const fetchStockData = async () => {
      if (activeTab.value === '个股搜索') return;
      loading.value = true;
      errorMessage.value = ''; // 清空错误信息
      try {
        let response;
        if (activeTab.value === '嫩牛') {
          response = await apiClient.get('/short-term-filter', { withCredentials: true });
        } else if (activeTab.value === '小牛') {
          response = await apiClient.get('/mid-term-filter', { withCredentials: true });
        } else if (activeTab.value === '大牛') {
          response = await apiClient.get('/long-term-filter', { withCredentials: true });
        }
        stockData.value = response.data;
        stockCounts.value[activeTab.value] = stockData.value.length;
        if (stockData.value.length > 0) {
          stockDates.value[activeTab.value] = stockData.value[0].trade_date;
        }
      } catch (error) {
        errorMessage.value = '无法获取股票数据，请稍后再试。';
      } finally {
        loading.value = false;
      }
    };

    // 搜索单个股票
    const searchStock = async () => {
      if (!searchQuery.value) return;
      loading.value = true;
      errorMessage.value = ''; // 清空错误信息
      try {
        const response = await apiClient.get('/single-stock-data', {
          params: { query: searchQuery.value },
          withCredentials: true,
        });
        stockData.value = [response.data]; // 返回单个股票数据
        stockCounts.value['个股搜索'] = stockData.value.length;
      } catch (error) {
        errorMessage.value = '无法找到该股票，请检查您的输入。';
      } finally {
        loading.value = false;
      }
    };

    // 切换选项卡
    const setActiveTab = (tab) => {
      activeTab.value = tab;
      stockData.value = []; // 清空数据以防残留
      searchQuery.value = ''; // 清空查询字段
      if (tab !== '个股搜索') {
        fetchStockData();
      } else {
        stockCounts.value['个股搜索'] = 0; // 重置个股搜索的股票计数
      }
    };

    // 页面初始化时获取数据
    onMounted(() => {
      fetchStockData();
    });

    return {
      stockData,
      loading,
      errorMessage,
      stockDates,
      stockCounts,
      tabs,
      activeTab,
      searchQuery,
      generateTradingViewLink,
      searchStock,
      setActiveTab,
    };
  },
};
</script>

<style scoped>
/* Customize active tab background color */
button.bg-blue-500 {
  background-color: #3b82f6;
}
button.text-gray-700:hover {
  background-color: #ebf8ff;
  border-color: #cbd5e0;
}
</style>
