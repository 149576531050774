<template>
  <AppLayout>
    <template #header>
      <h2 class="text-xl font-semibold text-gray-800 leading-tight">Buy it for Now</h2>
    </template>
    
    <div class="flex flex-col h-full p-4 md:p-6 bg-gray-50">
      <div class="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 space-y-4 md:space-y-0">
        <div class="flex items-center space-x-2">
          <h2 class="text-lg md:text-2xl font-bold text-gray-800">股票列表</h2>
        </div>
        <div class="text-left md:text-right">
          <span class="block text-red-500 font-bold">更新日期：{{ stockDate }}</span>
          <span class="block text-red-500 font-bold">股票数量：{{ filteredStockData.length }}</span>
        </div>
      </div>

      <div class="mb-6 p-4 bg-white rounded-lg shadow-lg">
        <div class="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4">
          <input
            v-model="searchName"
            type="text"
            placeholder="输入股票名称"
            class="border border-gray-300 rounded-lg px-4 py-2 w-full md:w-auto focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            v-model="searchSymbol"
            type="text"
            placeholder="输入股票代码"
            class="border border-gray-300 rounded-lg px-4 py-2 w-full md:w-auto focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <select v-model="break60Filter" class="border border-gray-300 rounded-lg px-4 py-2 w-full md:w-auto focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option value="">突破60</option>
            <option value="1">是</option>
            <option value="0">否</option>
          </select>
          <select v-model="break120Filter" class="border border-gray-300 rounded-lg px-4 py-2 w-full md:w-auto focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option value="">突破120</option>
            <option value="1">是</option>
            <option value="0">否</option>
          </select>
          <button @click="fetchStockData" class="bg-blue-500 text-white font-semibold px-4 py-2 rounded-lg w-full md:w-auto focus:outline-none hover:bg-blue-600 transition duration-150 ease-in-out">
            重置
          </button>
        </div>
      </div>

      <div v-if="errorMessage" class="text-red-500 text-center mb-4">{{ errorMessage }}</div>
      <div v-if="loading" class="text-center text-gray-600">正在加载中...</div>

      <div v-if="!loading && filteredStockData.length > 0" class="overflow-x-auto bg-white p-4 rounded-lg shadow-lg">
        <table class="min-w-full divide-y divide-gray-200 table-auto">
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="px-3 py-2 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">股票名称</th>
              <th scope="col" class="px-3 py-2 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">收盘价</th>
              <th scope="col" class="px-3 py-2 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">60日高点</th>
              <th scope="col" class="px-3 py-2 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">120日高点</th>
              <th scope="col" class="px-3 py-2 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">突破60</th>
              <th scope="col" class="px-3 py-2 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">突破120</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="stock in filteredStockData" :key="stock.ts_code" class="hover:bg-gray-100 transition-colors">
              <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-700">
                <a :href="generateTradingViewLink(stock.ts_code)" target="_blank" class="text-blue-500 hover:underline">{{ stock.name || '无名' }}</a>
              </td>
              <td class="px-3 py-4 text-center text-sm text-gray-700">{{ stock.close }}</td>
              <td class="px-3 py-4 text-center text-sm text-gray-700">{{ stock['60_high'] }}</td>
              <td class="px-3 py-4 text-center text-sm text-gray-700">{{ stock['120_high'] }}</td>
              <td class="px-3 py-4 text-center text-sm text-gray-700">{{ stock.break_60 ? '是' : '否' }}</td>
              <td class="px-3 py-4 text-center text-sm text-gray-700">{{ stock.break_120 ? '是' : '否' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="!loading && filteredStockData.length === 0" class="text-center mt-4 text-gray-600">没有可显示的数据。</div>
    </div>
  </AppLayout>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import apiClient from '@/plugins/axios';

export default {
  setup() {
    const allStockData = ref([]);
    const loading = ref(true);
    const errorMessage = ref('');
    const stockDate = ref('');
    const searchName = ref('');
    const searchSymbol = ref('');
    const break60Filter = ref('');
    const break120Filter = ref('');

    // 生成 TradingView 链接
    const generateTradingViewLink = (ts_code) => {
      if (!ts_code || typeof ts_code !== 'string') {
        return '#';
      }
      const prefix = ts_code.endsWith('.SZ') ? 'SZSE' : ts_code.endsWith('.SH') ? 'SSE' : 'Unknown';
      const symbol = ts_code.slice(0, -3);
      return `https://cn.tradingview.com/chart/g25qQEp8/?symbol=${prefix}%3A${symbol}`;
    };

    // 获取股票数据
    const fetchStockData = async () => {
      loading.value = true;
      try {
        const response = await apiClient.get('https://api.meogqy.com/api/stock-price-breakthrough');
        allStockData.value = response.data;

        if (allStockData.value.length > 0) {
          stockDate.value = allStockData.value[0].trade_date;
        }
      } catch (error) {
        errorMessage.value = '无法获取股票数据，请稍后再试。';
      } finally {
        loading.value = false;
      }
    };

    // 过滤数据
    const filteredStockData = computed(() => {
      return allStockData.value.filter(stock => {
        const matchesName = searchName.value ? stock.name && stock.name.includes(searchName.value) : true;
        const matchesSymbol = searchSymbol.value ? stock.ts_code && stock.ts_code.includes(searchSymbol.value) : true;
        const matchesBreak60 = break60Filter.value ? stock.break_60 == break60Filter.value : true;
        const matchesBreak120 = break120Filter.value ? stock.break_120 == break120Filter.value : true;

        return matchesName && matchesSymbol && matchesBreak60 && matchesBreak120;
      });
    });

    onMounted(() => {
      fetchStockData();
    });

    return {
      allStockData,
      loading,
      errorMessage,
      stockDate,
      searchName,
      searchSymbol,
      break60Filter,
      break120Filter,
      fetchStockData,
      filteredStockData,
      generateTradingViewLink
    };
  },
};
</script>
