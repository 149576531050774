<template>
  <AppLayout>
    <div class="max-w-2xl mx-auto p-8 mt-12 bg-white shadow-xl rounded-xl w-full">
      <h1 class="text-4xl font-bold mb-8 text-gray-900">我的仓位</h1>

      <!-- 显示模式 -->
      <div v-if="!isEditing" class="space-y-6">
        <p class="text-lg">
          <span class="font-semibold">初始资金:</span> {{ formatNumber(userPosition.initial_balance) }}
        </p>
        <p class="text-lg">
          <span class="font-semibold">最大亏损比例 (%):</span> {{ formatNumber(userPosition.max_loss_ratio) }}
        </p>
        <p class="text-lg">
          <span class="font-semibold">最大回撤 (%):</span> {{ formatNumber(userPosition.max_drawdown) }}
        </p>
        <p class="text-lg">
          <span class="font-semibold">最大亏损额:</span> {{ formatNumber(maxLossAmount) }}
        </p>
      </div>

      <!-- 编辑模式 -->
      <form v-else @submit.prevent="submit" class="space-y-6">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div>
            <label for="initial_balance" class="block text-sm font-medium text-gray-700">初始资金</label>
            <div class="mt-1">
              <input v-model="form.initial_balance" type="number" step="0.01" id="initial_balance" 
                     class="block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm 
                     focus:ring-blue-500 focus:border-blue-500 sm:text-sm transition duration-150 ease-in-out">
            </div>
          </div>

          <div>
            <label for="max_loss_ratio" class="block text-sm font-medium text-gray-700">最大亏损比例 (%)</label>
            <div class="mt-1">
              <input v-model="form.max_loss_ratio" type="number" step="0.01" id="max_loss_ratio" 
                     class="block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm 
                     focus:ring-blue-500 focus:border-blue-500 sm:text-sm transition duration-150 ease-in-out">
            </div>
          </div>

          <div>
            <label for="max_drawdown" class="block text-sm font-medium text-gray-700">最大回撤 (%)</label>
            <div class="mt-1">
              <input v-model="form.max_drawdown" type="number" step="0.01" id="max_drawdown" 
                     class="block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm 
                     focus:ring-blue-500 focus:border-blue-500 sm:text-sm transition duration-150 ease-in-out">
            </div>
          </div>

          <!-- 实时更新的最大亏损额 -->
          <div class="col-span-1 sm:col-span-2">
            <label class="block text-sm font-medium text-gray-700">最大亏损额</label>
            <div class="mt-1">
              <p class="text-lg">{{ formatNumber(maxLossAmount) }}</p>
            </div>
          </div>
        </div>

        <div class="flex justify-end mt-10 space-x-4">
          <button type="submit" class="px-6 py-3 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition duration-150 ease-in-out">
            保存
          </button>
          <button type="button" class="px-6 py-3 bg-gray-300 text-gray-700 rounded-lg shadow hover:bg-gray-400 transition duration-150 ease-in-out" @click="cancelEdit">
            取消
          </button>
        </div>
      </form>

      <!-- 编辑按钮 -->
      <div v-if="!isEditing" class="flex justify-end mt-10">
        <button @click="startEdit" class="px-6 py-3 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition duration-150 ease-in-out">
          更新仓位
        </button>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import apiClient from '@/plugins/axios';

export default {
  data() {
    return {
      isEditing: false,
      userPosition: {
        initial_balance: 0,
        max_loss_ratio: 0,
        max_drawdown: 0,
      },
      form: {
        initial_balance: 0,
        max_loss_ratio: 0,
        max_drawdown: 0,
      },
    };
  },
  computed: {
    // 动态计算最大亏损额
    maxLossAmount() {
      return this.form.initial_balance * (this.form.max_loss_ratio / 100);
    }
  },
  methods: {
    // 获取用户仓位信息
    async fetchUserPosition() {
      try {
        const response = await apiClient.get('/user-positions');
        if (response && response.data) {
          this.userPosition = response.data; // 初始化仓位数据
          this.form = { ...this.userPosition }; // 初始化表单数据
        }
      } catch (error) {
        console.error('获取仓位信息失败:', error);
        alert('获取仓位信息失败');
      }
    },
    startEdit() {
      // 进入编辑模式时，初始化表单数据
      this.form = { ...this.userPosition };
      this.isEditing = true;
    },
    cancelEdit() {
      this.isEditing = false;
    },
    async submit() {
      try {
        if (!this.userPosition.id) {
          // 如果没有 userPosition.id，执行 POST 请求创建新仓位
          const response = await apiClient.post('/user-positions', this.form);
          if (response && response.data) {
            alert('新仓位创建成功');
            this.userPosition = response.data; // 更新数据
            this.isEditing = false; // 退出编辑模式
          }
        } else {
          // 如果有 userPosition.id，执行 PUT 请求更新现有仓位
          const response = await apiClient.put(`/user-positions/${this.userPosition.id}`, this.form);
          if (response && response.data) {
            alert('仓位更新成功');
            this.userPosition = response.data; // 更新数据
            this.isEditing = false; // 保存成功后退出编辑模式
          }
        }
      } catch (error) {
        console.error('操作失败:', error.response ? error.response.data : error.message);
        alert('操作失败: 发生了错误，请稍后再试');
      }
    },
    formatNumber(value) {
      return value !== null && value !== undefined ? Number(value).toFixed(2) : '0.00';
    },
  },
  mounted() {
    // 页面加载时获取仓位信息
    this.fetchUserPosition();
  },
};
</script>

<style scoped>
/* 全局样式 */
.shadow-xl {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.focus\:ring-blue-500:focus {
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}

.focus\:border-blue-500:focus {
  border-color: #3b82f6;
}
</style>
