<template>
  <AppLayout>
    <template #header>
      <h2 class="text-xl font-semibold text-gray-800 leading-tight">Buy it for Now</h2>
    </template>

    <div class="flex flex-col h-full p-6">
      <div class="flex justify-between items-center mb-6">
        <div class="flex items-center space-x-2">
          <h2 class="text-2xl font-bold text-gray-800">股票列表</h2>
        </div>
        <div class="text-right">
          <span class="block text-red-500 font-bold">更新日期：{{ stockDate }}</span>
          <span class="block text-red-500 font-bold">股票数量：{{ stockCount }}</span>
        </div>
      </div>

      <div v-if="errorMessage" class="text-red-500 text-center mb-4">{{ errorMessage }}</div>
      <div v-if="loading" class="text-center">正在加载中...</div>

      <div v-if="!loading && stockData.length > 0">
        <table class="min-w-full divide-y divide-gray-200 table-fixed">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-2/12">股票名称</th>
              <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase w-1/12">收盘价</th>
              <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase w-1/12">MA5</th>
              <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase w-1/12">MA10</th>
              <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase w-1/12">MA20</th>
              <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase w-1/12">MA60</th>
              <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase w-1/12">MA120</th>
              <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase w-1/12">EMA20</th>
              <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase w-1/12">EMA60</th>
              <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase w-1/12">EMA120</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="stock in stockData" :key="stock.ts_code">
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-2/12">
                <a :href="generateTradingViewLink(stock.ts_code)" target="_blank">{{ stock.name }}</a>
              </td>
              <td class="px-6 py-4 text-center w-1/12">{{ stock.close }}</td>
              <td class="px-6 py-4 text-center w-1/12">{{ stock.MA5 }}</td>
              <td class="px-6 py-4 text-center w-1/12">{{ stock.MA10 }}</td>
              <td class="px-6 py-4 text-center w-1/12">{{ stock.MA20 }}</td>
              <td class="px-6 py-4 text-center w-1/12">{{ stock.MA60 }}</td>
              <td class="px-6 py-4 text-center w-1/12">{{ stock.MA120 }}</td>
              <td class="px-6 py-4 text-center w-1/12">{{ stock.EMA20 }}</td>
              <td class="px-6 py-4 text-center w-1/12">{{ stock.EMA60 }}</td>
              <td class="px-6 py-4 text-center w-1/12">{{ stock.EMA120 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="!loading && stockData.length === 0" class="text-center mt-4">没有可显示的数据。</div>
    </div>
  </AppLayout>
</template>

<script>
import { ref, onMounted } from 'vue';
import apiClient from '@/plugins/axios';

export default {
  setup() {
    const stockData = ref([]);
    const loading = ref(true);
    const errorMessage = ref('');
    const stockDate = ref('');
    const stockCount = ref(0);

    // 生成 TradingView 链接
    const generateTradingViewLink = (ts_code) => {
      const prefix = ts_code.endsWith('.SZ') ? 'SZSE' : ts_code.endsWith('.SH') ? 'SSE' : 'Unknown';
      const symbol = ts_code.slice(0, -3);
      return `https://cn.tradingview.com/chart/g25qQEp8/?symbol=${prefix}%3A${symbol}`;
    };

    // 获取股票数据
    const fetchStockData = async () => {
      loading.value = true;
      try {
        const response = await apiClient.get('/get-buy-it-for-now', { withCredentials: true });
        stockData.value = response.data;
        stockCount.value = stockData.value.length;
        if (stockData.value.length > 0) {
          stockDate.value = stockData.value[0].trade_date;
        }
      } catch (error) {
        errorMessage.value = '无法获取股票数据，请稍后再试。';
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchStockData();
    });

    return {
      stockData,
      loading,
      errorMessage,
      stockDate,
      stockCount,
      generateTradingViewLink,
    };
  },
};
</script>
