<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100 px-4 sm:px-6 lg:px-8">
    <!-- 登录表单卡片 -->
    <div class="w-full max-w-md bg-white shadow-xl rounded-lg p-8 sm:p-10">
      <!-- 顶部 Logo -->
      <div class="flex justify-center mb-4">
        <img src="logo.png" alt="Logo" class="h-12">
      </div>
      <!-- 副标题 -->
      <p class="text-gray-700 text-center mb-8">请输入您的登录信息</p>

      <!-- 登录表单 -->
      <form @submit.prevent="login">
        <!-- 用户名输入框 -->
        <div class="mb-6">
          <label class="block text-gray-700 font-medium mb-2" for="email">邮箱:</label>
          <input
            v-model="email"
            type="email"
            id="email"
            placeholder="请输入您的邮箱"
            required
            class="px-4 py-3 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-400 transition duration-150"
          />
        </div>
        <!-- 密码输入框 -->
        <div class="mb-6">
          <label class="block text-gray-700 font-medium mb-2" for="password">密码:</label>
          <input
            v-model="password"
            type="password"
            id="password"
            placeholder="请输入您的密码"
            required
            class="px-4 py-3 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-400 transition duration-150"
          />
        </div>
        <!-- 登录按钮 -->
        <button
          type="submit"
          class="px-6 py-3 w-full bg-green-600 text-white font-medium rounded hover:bg-green-700 transition duration-150"
        >
          登录
        </button>
      </form>

      <!-- 忘记密码链接 -->
      <div class="mt-4 text-center">
        <router-link to="/forgot-password" class="text-gray-600 hover:underline">
          忘记密码?
        </router-link>
      </div>

      <!-- 注册链接 -->
      <p class="mt-4 text-center text-gray-600">
        还没有账号?
        <router-link to="/register" class="text-green-600 hover:underline">创建一个账号</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import apiClient from '@/plugins/axios';

export default {
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        // 调用后端登录 API
        const response = await apiClient.post('/login', {
          email: this.email,
          password: this.password
        });

        // 保存 token 到 localStorage
        localStorage.setItem('auth_token', response.data.token);

        // 跳转到首页
        this.$router.push('/home');
      } catch (error) {
        console.error('登录失败:', error);
        alert('登录失败，请检查您的邮箱和密码');
      }
    }
  }
};
</script>

<style scoped>
/* 背景颜色 */
.bg-gray-100 {
  background-color: #f5f5f5;
  min-height: 100vh;
}

/* 卡片阴影 */
.shadow-xl {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

/* Logo 图片样式 */
img {
  max-height: 4rem;
  object-fit: contain;
}

/* 响应式调整 */
@media (max-width: 640px) {
  .p-10 {
    padding: 2rem; /* 减少小屏幕上的内边距 */
  }
}
</style>
