<template>
  <AppLayout>
    <template #header>
      <h2 class="text-xl font-semibold text-gray-800 leading-tight">Buy it for Now</h2>
    </template>

    <div class="flex flex-col h-full p-4 md:p-6 bg-gray-50">
      <!-- Title and Count -->
      <div class="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 space-y-4 md:space-y-0">
        <div class="flex items-center space-x-2">
          <h2 class="text-lg md:text-2xl font-bold text-gray-800">股票列表</h2>
        </div>
        <div class="text-left md:text-right">
          <span class="block text-red-500 font-bold">更新日期：{{ stockDate }}</span>
          <span class="block text-red-500 font-bold">股票数量：{{ stockCount }}</span>
        </div>
      </div>

      <!-- Search and Filter Area -->
      <div class="mb-6 p-4 bg-white rounded-lg shadow-lg">
        <div class="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4">
          <input
            v-model="searchName"
            type="text"
            placeholder="输入股票名称"
            class="border border-gray-300 rounded-lg px-4 py-2 w-full md:w-auto focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            v-model="searchSymbol"
            type="text"
            placeholder="输入股票代码"
            class="border border-gray-300 rounded-lg px-4 py-2 w-full md:w-auto focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button @click="searchStocks" class="bg-blue-500 text-white font-semibold px-4 py-2 rounded-lg w-full md:w-auto focus:outline-none hover:bg-blue-600 transition duration-150 ease-in-out">
            搜索
          </button>
        </div>
      </div>

      <!-- Sorting Options (Mobile View) -->
      <div class="lg:hidden mb-6 p-4 bg-white rounded-lg shadow-lg">
        <div class="flex items-center justify-between space-x-4">
          <label class="text-sm font-medium text-gray-700">排序字段</label>
          <select v-model="sortKey" class="border border-gray-300 rounded-lg px-4 py-2 focus:outline-none">
            <option value="slope_5_rank">Slope 5 Rank</option>
            <option value="slope_10_rank">Slope 10 Rank</option>
            <option value="slope_20_rank">Slope 20 Rank</option>
            <option value="slope_60_rank">Slope 60 Rank</option>
          </select>
          <button @click="toggleSortOrder" class="text-sm font-medium text-gray-700">
            {{ sortOrder === 1 ? '升序' : '降序' }}
          </button>
        </div>
      </div>

      <!-- Error and Loading Messages -->
      <div v-if="errorMessage" class="text-red-500 text-center mb-4">{{ errorMessage }}</div>
      <div v-if="loading" class="text-center text-gray-600">正在加载中...</div>

      <!-- Data Table (Desktop View) -->
      <div v-if="!loading && stockData.length > 0" class="hidden lg:block overflow-x-auto bg-white p-4 rounded-lg shadow-lg">
        <table class="min-w-full divide-y divide-gray-200 table-auto">
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="px-3 py-2 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">股票名称</th>
              <th scope="col" class="px-3 py-2 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">代码</th>
              <th scope="col" class="px-3 py-2 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider hidden md:table-cell">交易日期</th>
              <th
                @click="sortData('slope_5_rank')"
                class="px-3 py-2 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer"
              >
                Slope 5
                <span class="text-xs text-gray-500">Rank: {{ rankDisplay('slope_5_rank') }}</span>
              </th>
              <th
                @click="sortData('slope_10_rank')"
                class="px-3 py-2 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer hidden md:table-cell"
              >
                Slope 10
                <span class="text-xs text-gray-500">Rank: {{ rankDisplay('slope_10_rank') }}</span>
              </th>
              <th
                @click="sortData('slope_20_rank')"
                class="px-3 py-2 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer hidden md:table-cell"
              >
                Slope 20
                <span class="text-xs text-gray-500">Rank: {{ rankDisplay('slope_20_rank') }}</span>
              </th>
              <th
                @click="sortData('slope_60_rank')"
                class="px-3 py-2 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer hidden md:table-cell"
              >
                Slope 60
                <span class="text-xs text-gray-500">Rank: {{ rankDisplay('slope_60_rank') }}</span>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="stock in sortedStockData" :key="stock.ts_code" class="hover:bg-gray-100 transition-colors">
              <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-700">
                <a :href="generateTradingViewLink(stock.ts_code)" target="_blank" class="text-blue-500 hover:underline">{{ stock.name }}</a>
              </td>
              <td class="px-3 py-4 text-center text-sm text-gray-700">{{ stock.symbol }}</td>
              <td class="px-3 py-4 text-center text-sm text-gray-700 hidden md:table-cell">{{ stock.trade_date }}</td>
              <td class="px-3 py-4 text-center text-sm text-gray-700">
                {{ stock.slope_5 }} (Rank: {{ stock.slope_5_rank }})
              </td>
              <td class="px-3 py-4 text-center text-sm text-gray-700 hidden md:table-cell">
                {{ stock.slope_10 }} (Rank: {{ stock.slope_10_rank }})
              </td>
              <td class="px-3 py-4 text-center text-sm text-gray-700 hidden md:table-cell">
                {{ stock.slope_20 }} (Rank: {{ stock.slope_20_rank }})
              </td>
              <td class="px-3 py-4 text-center text-sm text-gray-700 hidden md:table-cell">
                {{ stock.slope_60 }} (Rank: {{ stock.slope_60_rank }})
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Card Layout (Mobile View) -->
      <div class="lg:hidden">
        <div v-for="stock in sortedStockData" :key="stock.ts_code" class="bg-white shadow-lg rounded-lg mb-4 p-4">
          <h3 class="text-lg font-semibold text-gray-800 mb-2">
            <a :href="generateTradingViewLink(stock.ts_code)" target="_blank" class="text-blue-500 hover:underline">{{ stock.name }}</a>
          </h3>
          <div class="text-gray-700 text-sm space-y-1">
            <div><span class="font-semibold">代码：</span>{{ stock.symbol }}</div>
            <div><span class="font-semibold">交易日期：</span>{{ stock.trade_date }}</div>
            <div><span class="font-semibold">Slope 5：</span>{{ stock.slope_5 }} (Rank: {{ stock.slope_5_rank }})</div>
            <div><span class="font-semibold">Slope 10：</span>{{ stock.slope_10 }} (Rank: {{ stock.slope_10_rank }})</div>
            <div><span class="font-semibold">Slope 20：</span>{{ stock.slope_20 }} (Rank: {{ stock.slope_20_rank }})</div>
            <div><span class="font-semibold">Slope 60：</span>{{ stock.slope_60 }} (Rank: {{ stock.slope_60_rank }})</div>
          </div>
        </div>
      </div>

      <div v-if="!loading && stockData.length === 0" class="text-center mt-4 text-gray-600">没有可显示的数据。</div>
    </div>
  </AppLayout>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import apiClient from '@/plugins/axios';
import debounce from 'lodash.debounce';

export default {
  setup() {
    const stockData = ref([]);
    const loading = ref(true);
    const errorMessage = ref('');
    const stockDate = ref('');
    const stockCount = ref(0);
    const searchName = ref('');
    const searchSymbol = ref('');
    const sortKey = ref('slope_5_rank'); // 默认排序字段为排名
    const sortOrder = ref(1);

    // 生成 TradingView 链接
    const generateTradingViewLink = (ts_code) => {
      if (!ts_code) return ''; // 如果 ts_code 为空或无效，返回空字符串
      const prefix = ts_code.endsWith('.SZ') ? 'SZSE' : ts_code.endsWith('.SH') ? 'SSE' : 'Unknown';
      const symbol = ts_code.slice(0, -3);
      return `https://cn.tradingview.com/chart/g25MItD9/?symbol=${prefix}:${symbol}`;
    };

    // 请求数据
    const fetchStockData = async (name = '', symbol = '') => {
      loading.value = true;
      let url = `https://api.meogqy.com/api/get-stock-hot-rank`;
      if (name) url += `&name=${encodeURIComponent(name)}`;
      if (symbol) url += `&symbol=${encodeURIComponent(symbol)}`;

      try {
        const response = await apiClient.get(url);
        stockData.value = Object.values(response.data); // 获取数据
        stockCount.value = stockData.value.length;
        stockDate.value = stockData.value.length ? stockData.value[0].trade_date : '';
      } catch (error) {
        errorMessage.value = '无法获取股票数据，请稍后再试。';
      } finally {
        loading.value = false;
      }
    };

    // 防抖搜索
    const searchStocks = debounce(() => {
      fetchStockData(searchName.value, searchSymbol.value);
    }, 500);

    // 排序
    const sortedStockData = computed(() => {
      return stockData.value.slice().sort((a, b) => {
        const aRank = a[sortKey.value] || 9999;
        const bRank = b[sortKey.value] || 9999;
        return (aRank - bRank) * sortOrder.value;
      });
    });

    const sortData = (key) => {
      if (sortKey.value === key) {
        sortOrder.value = -sortOrder.value; // Toggle sort order
      } else {
        sortKey.value = key;
        sortOrder.value = 1;
      }
    };

    const rankDisplay = (key) => {
      return sortedStockData.value.length > 0 ? sortedStockData.value[0][key] : '';
    };

    onMounted(() => {
      fetchStockData();
    });

    return {
      stockData,
      loading,
      errorMessage,
      stockDate,
      stockCount,
      searchName,
      searchSymbol,
      sortKey,
      sortOrder,
      fetchStockData,
      searchStocks,
      sortedStockData,
      generateTradingViewLink,
      sortData,
      rankDisplay
    };
  }
};
</script>

<style scoped>
/* Customize styles here */
</style>
